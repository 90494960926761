<template>
  <div class="help-page second-page">help</div>
</template>

<script>
  export default {
    name: 'Help'
  }
</script>

<style lang="less" scoped>
  .help-page {

  }
</style>
